import React from 'react';
import FlagIcon from '@material-ui/icons/FlagOutlined';
import LocationCityIcon from '@material-ui/icons/LocationCityOutlined';
import PinDropIcon from '@material-ui/icons/PinDropOutlined';
import LocationSearchingIcon from '@material-ui/icons/LocationSearchingOutlined';
import Landscape from '@material-ui/icons/LandscapeOutlined';
import LanguageIcon from '@material-ui/icons/LanguageOutlined';
import { INavigationLink } from '@wings-shared/layout';
import { CountryModuleSecurity } from '../../Tools';
import { COUNTRY_MODULE_NAMES } from '../../Enums';
import { DocumentIcon, InfoIcon, WorldIcon, SettingIcon, FirIcon, WarningIcon, PeopleIcon } from '@uvgo-shared/icons';
import { baseApiPath } from '@wings/shared';
import { Utilities } from '@wings-shared/core';

/* istanbul ignore next */
export const countrySidebarOptions = (defaultOptions: boolean, isDisabled: boolean = false): INavigationLink[] =>
  defaultOptions ? defaultCountryOptions : upsertCountryOptions(isDisabled);

export const upsertTabBasePathFinder = (paths: string[]) =>
  window.location.pathname
    .split('/')
    .filter(x => !paths.includes(x))
    .join('/');

export const upsertCountryBackNavLink = (idQuery: string | number) =>
  idQuery ? `/countries/continents/${idQuery}/countries` : '/countries';

/* istanbul ignore next */
export const updatedBackNavigation = (): { backNavTitle: string; updatedBackNavLink: string } => {
  const pathList = window.location.pathname.split('/');
  const slicedPathList = pathList.slice(0, pathList.length - 2); // Excluding the last two elements
  const titleQuery = slicedPathList[slicedPathList.length - 1];
  const backNavTitle = titleQuery[0]?.toUpperCase() + titleQuery.slice(1);
  const updatedBackNavLink = slicedPathList.join('/');
  return { updatedBackNavLink, backNavTitle };
};

export const defaultCountryOptions: INavigationLink[] = [
  { to: '', title: 'Countries', icon: <WorldIcon /> },
  { to: 'states', title: 'States', icon: <FlagIcon /> },
  { to: 'cities', title: 'Cities', icon: <LocationCityIcon /> },
  {
    to: 'islands',
    title: 'Islands',
    icon: <Landscape />,
    isHidden: !CountryModuleSecurity.isSubModuleVisible(COUNTRY_MODULE_NAMES.ISLANDS),
  },
  {
    to: 'continents',
    title: 'Continents',
    icon: <LanguageIcon />,
    isHidden: !CountryModuleSecurity.isSubModuleVisible(COUNTRY_MODULE_NAMES.CONTINENTS),
  },
  {
    to: 'firs',
    title: 'FIRs',
    icon: <FirIcon />,
    isHidden: !CountryModuleSecurity.isSubModuleVisible(COUNTRY_MODULE_NAMES.FIRS),
  },
  {
    to: 'regions',
    title: 'Regions',
    icon: <LocationSearchingIcon />,
    isHidden: !CountryModuleSecurity.isSubModuleVisible(COUNTRY_MODULE_NAMES.REGIONS),
  },
  {
    to: 'metros',
    title: 'Metros',
    icon: <PinDropIcon />,
    isHidden: !CountryModuleSecurity.isSubModuleVisible(COUNTRY_MODULE_NAMES.METROS),
  },
  {
    to: 'aeronautical-information',
    title: 'AIP',
    icon: <DocumentIcon />,
    isHidden: !CountryModuleSecurity.isSubModuleVisible(COUNTRY_MODULE_NAMES.AIP),
  },
  { to: 'bulletins', title: 'Bulletins', icon: <WarningIcon /> },
  { to: 'purged-bulletins', title: 'Purged Bulletins', icon: <WarningIcon /> },
  { to: 'settings', title: 'Settings', icon: <SettingIcon /> },
];

export const upsertCountryOptions = (isDisabled: boolean): INavigationLink[] => [
  { to: '', title: 'General Information', icon: <InfoIcon /> },
  {
    to: 'operational-requirements/general',
    title: 'Operational Requirements',
    icon: <LocationSearchingIcon />,
    isDisabled,
  },
  { to: 'associated-regions', title: 'Associated Regions', icon: <LocationSearchingIcon />, isDisabled },
  { to: 'associated-aips', title: 'Associated AIPs', icon: <DocumentIcon />, isDisabled },
  { to: 'associated-firs', title: 'Associated FIRs', icon: <FirIcon />, isDisabled },
];

export const operationalRequirementsOptions = (): INavigationLink[] => [
  { to: 'general', title: 'General', icon: <PeopleIcon size="medium" /> },
  { to: 'cabotage', title: 'Cabotage', icon: <PeopleIcon size="medium" /> },
  { to: 'flight-planning', title: 'Flight Planning', icon: <PeopleIcon size="medium" /> },
  {
    to: 'custom',
    title: 'Customs',
    icon: <PeopleIcon size="medium" />,
  },
];

export const updateCountrySidebarOptions = (tabQuery: string): INavigationLink[] =>
  Utilities.updateSidebarOptions(defaultCountryOptions, tabQuery);

export const upsertCountrySidebarOptions = (tabQuery: string, isDisabled: boolean): INavigationLink[] =>
  Utilities.updateSidebarOptions(upsertCountryOptions(isDisabled), tabQuery);
